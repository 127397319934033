import type {
    ComposableOptionsBase,
    FullImage,
    FullImageGeneratedSizes,
    FullImageSizeItemInternal,
    FullImageSizeItem,
    FullImageSizes,
    FullImageSizesList
} from '~/types';
import { useCdnResource } from './';
import { random as lo_random } from 'lodash-es';
import { isUrl } from '~/utils';
import {
    imageSizeTypes,
    imageSizesDef,
    type ImageSizeTypes,
    type ImagesSizeDef,
    type ImagesSizesDef
} from '~/models';

const cdnRepo = 'cms-static';
const fallbackImgPath = '/products/img/fallback-400.jpg';


export interface UseFullImageOptions extends ComposableOptionsBase {}
export function useFullImage(options: Partial<UseFullImageOptions> = {}) {
    const { cdnBasePathImages, getResourceCdnUrl } = useCdnResource();
    const _fallbackImage = getResourceCdnUrl(fallbackImgPath, cdnRepo);
    const fallbackImage = ref<string>(_fallbackImage);
    const genUid = () => `full_image_temp_${lo_random(-3000, -1)}`;
    const imageSizeTypesOrdered = imageSizeTypes.slice().reverse();


    function getFullImgFromUrl(url: string, alt: string = ''): FullImage {
        return {
            url,
            alt,
            uid: genUid(),
            showDropShadow: false,
            clippedEdges: []
        };
    }

    function getFallbackImage(obj: Partial<FullImage> = {} ): FullImage {
        return Object.assign<
            FullImage,
            Partial<FullImage>
        >({
            uid: genUid(),
            url: fallbackImage.value,
            alt: 'Image not found',
            showDropShadow: false,
            clippedEdges: []
        }, obj);
    }

    function getSingleImageUrlFromFullImage(fullImage: Nilish<FullImage>, imgType: ImageSizeTypes): Nullable<string> {
        if (fullImage) {
            try {
                const sizeDef = imageSizesDef[imgType];
                const { url } = fullImage;
                const { w, h, q } = sizeDef;
                const sizeDefPart = makeImageParamPath(w, h, q);
                return url.replace('/default/', `/${sizeDefPart}/`);
            } catch (err) {}
        }

        return null;
    }

    function getImgAttrsFromFullImage(fullImage: FullImage, sizesFactor = 1, overrideSizesList?: Nilish<ImageSizeTypes[]>): FullImageGeneratedSizes {
        const srcset: string[] = [];
        const sizes: string[] = [];
        const sizesMap: Record<string, string> = {};
        const sizesList: FullImageSizesList[] = [];
        const { url } = fullImage;
        const overrideSizesListOrdered = overrideSizesList?.slice().reverse();

        (overrideSizesListOrdered ?? imageSizeTypesOrdered).forEach((imgType: ImageSizeTypes) => {
            const sizeDef = imageSizesDef[imgType];
            const { w: width, h: height, q: quality } = sizeDef;
            const sizeDefPart = makeImageParamPath(width, height, quality);
            const finalUrl = url.replace('/default/', `/${sizeDefPart}/`);
            
            srcset.push(`${finalUrl} ${width}w`);
            sizes.push(`(min-width: ${width * sizesFactor}px) ${width}px`);
            sizesMap[imgType] = finalUrl;
            sizesList.push([width, height, imgType]);
        });

        return {
            sizesMap,
            sizesList,
            srcset: srcset.join(','),
            sizes: sizes.join(',')
        };
    }

    function makeImageParamPath(w: number, h: number, q: number, c?: string): string {
        let path = `w${w}-h${h}-q${q}`;

        if (c) {
            path = `${path}-${c}`;
        }

        return path;
    }

    return {
        getFullImgFromUrl,
        fallbackImage,
        getFallbackImage,
        getSingleImageUrlFromFullImage,
        getImgAttrsFromFullImage
    };
}
